<template>
  <StudentsTable />
</template>
<script>
import StudentsTable from "../Students/components/StudentTableComponents/StudentsTable";
export default {
  name: "Students",
  components : {StudentsTable}
}
</script>
<style scoped>

</style>