var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('base-table-with-header',{attrs:{"data":{
    headerOptions : { withSearch : true , withExport : true},
    tableHeader : _vm.studentHeaders ,
    tableItems : _vm.studentRows ,
    extraOptions : {
      footerOptions : {
        itemsPerPageText : this.$t('table.rowNumber')
      },
    },
    withActions : true
  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var data = ref.data;
return [_c('router-link',{attrs:{"to":{name : 'StudentEdit' , params : {id : data.student_id}, query: {email: data.student_email}}}},[_c('v-btn',{attrs:{"text":"","icon":"","color":"secondary lighten-2"}},[_c('v-icon',[_vm._v(_vm._s(_vm.editIcon))])],1)],1),_c('router-link',{attrs:{"to":{name : 'viewStudents' , params : {id : data.student_id}, query: {email: data.student_email}}}},[_c('v-btn',{attrs:{"text":"","icon":"","color":"secondary lighten-2"}},[_c('v-icon',[_vm._v(_vm._s(_vm.eyeIcon))])],1)],1)]}}])},[_c('h1',{staticClass:"font-weight-bold",style:({'color' : this.primaryColor}),attrs:{"slot":"tableTitle"},slot:"tableTitle"},[_vm._v(_vm._s(_vm.$t('students.title')))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }