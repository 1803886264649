<template>
  <v-row>
    <base-table-with-header :data="{
      headerOptions : { withSearch : true , withExport : true},
      tableHeader : studentHeaders ,
      tableItems : studentRows ,
      extraOptions : {
        footerOptions : {
          itemsPerPageText : this.$t('table.rowNumber')
        },
      },
      withActions : true
    }"
    >
      <h1 slot="tableTitle" class="font-weight-bold" :style="{'color' : this.primaryColor}">{{ $t('students.title') }}</h1>
      <template #default="{data}">
        <router-link :to="{name : 'StudentEdit' , params : {id : data.student_id}, query: {email: data.student_email}}">
          <v-btn text icon color="secondary lighten-2">
            <v-icon>{{editIcon}}</v-icon>
          </v-btn>
        </router-link>
        <router-link :to="{name : 'viewStudents' , params : {id : data.student_id}, query: {email: data.student_email}}">
          <v-btn text icon color="secondary lighten-2">
            <v-icon>{{eyeIcon}}</v-icon>
          </v-btn>
        </router-link>
      </template>
    </base-table-with-header>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BaseTableWithHeader from "../StudentTableComponents/StudentsBactTable";
import moment from 'moment';
export default {
  name: "Students",
  components: {BaseTableWithHeader},
  data() {
    return {
      studentHeaders: [
        {
          text: this.$t('students.ID'),
          align: 'center',
          sortable: true,
          value: 'student_id',
        },
        {text: this.$t('students.name'), value: 'student_name', align: 'center'},
        {text: this.$t('students.email'), value: 'student_email', align: 'center'},
        {text: this.$t('students.birthDate'), value: 'student_birthDate', align: 'center'},
        {text: this.$t('students.createdAt'), value: 'registerd_on', align: 'center'},
        {text: this.$t('students.totalCourses'), value: 'total_courses', align: 'center'},
        {text: this.$t('students.totalPayments'), value: 'total_Paid', align: 'center'},
        {text: this.$t('students.completed'), value: 'completed', align: 'center'},
        {text: this.$t('students.Actions'), value: 'actions', align: 'center'},
      ],
      studentRows: [],
    }
  },
  created () {
    this.getAllStudents().then(() => {
      this.displayStudents()
    });
  },
  methods: {
   ...mapActions("DashBoard/Students", ["getAllStudents"]),
    displayStudents () {
      this.studentRows = []
       this.getStudents.forEach(student => {
        const displayStudent = {...student}

        displayStudent.student_birthDate = moment(displayStudent.student_birthDate).format("YYYY-MM-DD");

        displayStudent.registerd_on = moment(displayStudent.registerd_on).format("YYYY-MM-DD");

        this.studentRows.push(displayStudent)
      });
    }
  },
  computed: mapGetters("DashBoard/Students", ["getStudents"]),
  filters: {
    moment: function(date) {
      return moment(date).format("YYYY-MM-DD");
    }
  }
}
</script>

<style scoped>

</style>
