<template>
  <v-row>
    <v-row class="uk-container uk-margin-large-top">
      <v-col cols="12">
        <slot name="tableTitle"></slot>
      </v-col>
      <v-col cols="12">
        <table-actions-nav :data="data" :options="data.headerOptions"></table-actions-nav>
      </v-col>
    </v-row>
    <v-row class="uk-margin-small-top">
      <template>
        <v-data-table
          :headers="data.tableHeader"
          :items="data.tableItems"
          :items-per-page="5"
          class="elevation-1 w-100"
          :footer-props="data.extraOptions.footerOptions"
          :loading="loadingStudents"
          mobile-breakpoint="700"
        >
          <template v-slot:item.actions="{item}">
            <v-row
              align="center"
              justify="center"
            >
              <slot  :data="item"></slot>
            </v-row>
          </template>
        </v-data-table>
      </template>
    </v-row>
  </v-row>
</template>

<script>
import { mapState } from 'vuex';
import TableActionsNav from "../StudentTableComponents/TableActionsNav";

export default {
  name: "StudentsBactTable",
  components: {TableActionsNav},
  props: {
    data: Object,
  },
  computed: mapState("DashBoard/Students", ["loadingStudents"])
}
</script>

<style scoped>
    .v-data-footer__pagination {
        direction: ltr !important;
    }
</style>
